import { render, staticRenderFns } from "./MallOperation.vue?vue&type=template&id=0bb5a0a8&scoped=true&"
import script from "./MallOperation.vue?vue&type=script&lang=js&"
export * from "./MallOperation.vue?vue&type=script&lang=js&"
import style0 from "./MallOperation.vue?vue&type=style&index=0&id=0bb5a0a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb5a0a8",
  null
  
)

export default component.exports