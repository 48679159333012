<template>
  <div class="mall-operation">
    <el-button type="text" size="medium" @click.stop="onOperation()">操作记录</el-button>
    <!-- 列表弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="40%">
      <!-- Table表格 -->
      <el-table :data="tableData" border stripe :header-cell-style="{
        background: '#FAFAFA', color: '#000000'
      }">
        <el-table-column label="操作时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name || "-" }}</span>
            <span>&nbsp;丨&nbsp;</span>
            <span>{{ scope.row.mobile || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.operateTypeName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.content || "-" }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderOperateLog } from "@/api/mall/mall";
export default {
  props: ['row', 'businessType'],
  data() {
    return {
      // 列表弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      tableData: [],//表格数据
    }
  },
  methods: {
    // 【请求】获取操作记录
    getOrderOperateLog() {
      let data = this.row;
      getOrderOperateLog(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
        }
      });
    },


    // 【监听】打开操作记录
    onOperation() {
      this.editShow = true;
      this.editTitle = this.businessType == 2 ? "售卖柜操作记录" : "派送操作记录";
      this.getOrderOperateLog();
    }
  }
}
</script>

<style lang="scss" scoped>
.mall-operation {
  display: inline-flex;
  margin-right: .1rem;
}
</style>