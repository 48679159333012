<template>
  <div class="mall-refund">
    <el-button type="text" size="medium" @click.stop="onRefund()"
      >退款</el-button
    >
    <!-- 编辑弹框 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="30%"
    >
      <p class="gray mb15">商品购买详情</p>
      <p class="black mb15">商品信息：</p>
      <li
        class="goods-item"
        v-for="item in orderInfo.orderDetailVos"
        :key="item.detailId"
      >
        <el-image
          class="mr15"
          style="width: 0.4rem; height: 0.4rem"
          :src="item.imagePic"
          :preview-src-list="[item.imagePic]"
        >
        </el-image>
        <div class="name">
          <p class="name-title black">
            <span>{{ item.goodsName }}</span>
            <span>{{ item.originalPrice }}</span>
          </p>
          <p class="count gray">
            <span>x{{ item.quantity }}</span>
          </p>
        </div>
      </li>
      <p class="black mb15">
        下单时间：<span class="gray">{{ orderInfo.paymentDate }}</span>
      </p>
      <p class="black mb15">
        商品金额：<span class="gray">{{ orderInfo.originalPrice }}</span>
      </p>
      <p class="black mb15">
        实付金额：<span class="gray">{{ orderInfo.paymentPrice }}</span>
      </p>
      <p class="black mb15">
        支付方式：<span class="gray">{{ orderInfo.paymentInfo }}</span>
      </p>
      <p class="black mb15">
        已退金额：<span class="gray">{{ orderInfo.refundInfo }}</span>
      </p>
      <p class="black mb15">
        已收回积分：<span class="gray">{{ orderInfo.pointsRecoveryAmount }}</span>
      </p>
      <el-form :model="params" :rules="rules" ref="form-ref" inline>
        <div class="inputBox">
          <el-form-item
            :label="'微信退款(可退款金额：' + orderInfo.canRefundAmount + ')'"
            prop="wxRefundAmount"
            v-if="orderInfo.canRefundAmount != 0"
          >
            <el-input
              v-model.trim="params.wxRefundAmount"
              autocomplete="off"
              maxlength="16"
              @input="(v) => (params.wxRefundAmount = v.replace(/[^\d.]/g, ''))"
              placeholder="请输入微信退款金额"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="
              '本金退款(可退款金额：' + orderInfo.canRefundBalanceAmount + ')'
            "
            prop="bjRefundAmount"
            v-if="orderInfo.canRefundBalanceAmount != 0"
          >
            <el-input
              v-model.trim="params.bjRefundAmount"
              autocomplete="off"
              maxlength="16"
              @input="(v) => (params.bjRefundAmount = v.replace(/[^\d.]/g, ''))"
              placeholder="请输入本金退款金额"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="orderInfo.canPointsRecoveryAmount != 0" prop="pointsRefundAmount"
                :label="'收回积分(可收回积分数：' + orderInfo.canPointsRecoveryAmount + ')'">
                <el-input type="number" v-model.trim="params.pointsRefundAmount" autocomplete="off" placeholder="请输入收回积分数"
                  @keydown.native="(e) =>
    (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
      e.key
    )
      ? false
      : e.returnValue)
      ">
                </el-input>
              </el-form-item>
        </div>
        <section class="form-footer">
          <el-button
            type="info"
            plain
            disabled
            v-if="
              orderInfo.canRefundAmount == 0 &&
              orderInfo.canRefundBalanceAmount == 0
            "
            >已退款</el-button
          >
          <el-button type="primary" @click="onSubmit('form-ref')" v-else
            >确认退款</el-button
          >
        </section>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderInfo, getOrderRefund } from "@/api/mall/mall";
import { rules } from "@/db/rules";
export default {
  props: ["row", "businessType"],
  data() {
    return {
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      orderInfo: "", //订单详情
      // from表单
      rules,
      params: {
        wxRefundAmount: "",
        bjRefundAmount: "",
        pointsRefundAmount:''
      },
    };
  },
  methods: {
    // 判断是否大于可退款金额
    goCheckRefundMoney() {
      if (this.params.wxRefundAmount > this.orderInfo.canRefundAmount) {
        return 1;
      } else if (
        this.params.bjRefundAmount > this.orderInfo.canRefundBalanceAmount
      ) {
        return 2;
      } 
      else if (this.params.pointsRefundAmount > this.orderInfo.canPointsRecoveryAmount) {
        return 5;
      } else {
        return 6;
      }
    },
    // 【请求】详情
    getOrderInfo() {
      let data = this.row;
      getOrderInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.orderInfo = res.data;
        }
      });
    },

    // 【请求】退款
    getOrderRefund() {
      let data = {
        businessType: this.businessType,
        orderId: this.row.orderId,
        refundPrice: this.params.wxRefundAmount,
        balanceRefundAmount: this.params.bjRefundAmount,
        pointsRecoveryAmount: this.params.pointsRefundAmount,
      };
      getOrderRefund(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.$emit("reload");
        }
      });
    },

    // 【监听】退款
    onRefund() {
      this.editShow = true;
      this.editTitle =
        this.businessType == 2 ? "售卖柜订单退款" : "派送订单退款";
      this.params = {
        wxRefundAmount: "",
        bjRefundAmount: "",
      };
      this.getOrderInfo();
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let errType = this.goCheckRefundMoney();
          switch (errType) {
            case 1:
              this.$message({
                message: "微信退款金额超出最大可退款金额",
                type: "error",
              });
              break;
            case 2:
              this.$message({
                message: "本金退款金额超出最大可退款金额",
                type: "error",
              });
              break;
              case 5:
              this.$message({
                message: "收回积分超出最大可收回积分",
                type: "error",
              });
              this.loading = false;

              break;
            case 6:
              this.getOrderRefund();
              break;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mall-refund {
  display: inline-flex;
  margin-right: 0.1rem;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.gray {
  color: rgba(153, 153, 153, 1);
}

.black {
  color: rgba(51, 51, 51, 1);
}

.goods-item {
  margin-bottom: 0.15rem;
  padding: 0.15rem;
  background: rgba(249, 249, 249, 1);
  display: flex;
  align-items: center;
}

.name {
  flex: 1;
}

.name-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count {
  display: flex;
  justify-content: flex-end;
}
.inputBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 50%;
    .el-input {
      width: 240px;
    }
  }
}
.form-footer {
  display: flex;
  justify-content: center;
}
</style>